import React, {Component} from 'react';
// import ReactDOM from 'react-dom'
// import {getScrollPosition, distanceToSection} from "./Helpers/ScrollPosition";

export default class MenuBarItem extends Component {
    constructor(props) {
        super(props);
        const height = 25;
        this.state = {
            name: this.props.menuText,
            sectionPosition: null,
            toggled: false,
            styles: {
                cursor: 'pointer',
                width: 'auto',
                position: 'absolute',
                alignItems: 'left',
                textAlign: 'left',
                marginLeft: '10px',
                height: height,
                bottom: ((5-this.props.menuID) * height),
                zIndex: '-1'
            }
        };

        this.anchorTarget = null;
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        e.preventDefault();
        this.anchorTarget.scrollIntoView({behavior: 'smooth', block:"start"});

    }

    abortController = new AbortController()

    componentDidMount() {
        this.anchorTarget = document.getElementById(this.props.menuText)
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    render(){
        return <div id={`menuBar${this.props.menuText}`} className='menu-bar-item' style={this.state.styles} onClick={this.handleClick} >
            {this.props.menuText}
        </div>
    }
}