import React, {Component} from 'react';


export default class Contact extends Component {
    render(){
        // Sections Title / type (i.e. List, table, pdf, map, or persons)
        return <div id='Contact' className={'section'}>
            <h2>Contact Information</h2>
            <ul>
                <li>MTI@mfgtec.com</li>
                <li>206.763.3161</li>
                <li>Martin Lyman: Sales Manager</li>
                <li>Kevin Wilgus: Manufacturing Manager</li>
                <li>Brandi Lyman: Accounts Payable/HR</li>
                <li>Jared Kanda: Quality Manager</li>
                <li>Office Hours</li>
                <li>Mon-Fri 8:00 AM-5:00 PM</li>
            </ul>
        </div>
    }
}