import React, {Component} from 'react'
// import Helpers from "./Helpers/Helpers";
import Header from './Header'
import Sections from './Sections'
// import InitializeTHREE from "./Threejs/InitializeTHREE";

const sections =['Services', 'Equipment', 'Certifications', 'Contact', 'Directions', 'Careers'];


class Main extends Component {
  render() {
    return(<React.Fragment>
      {/*<InitializeTHREE/>*/}
      {/*<Helpers/>*/}
      <div id='Background'/>
      <Header menu-items={sections}/>
      <Sections sections={sections}/>
      <div id="Footer">
        <p>Manufacturing Technology, Inc. 2020</p>
      </div>
    </React.Fragment>)
  }
}

export default Main