import React, {Component} from 'react';
import MenuBarItem from "./MenuBarItem";

class MenuBar extends Component {
  constructor(props) {
    super(props);
    const lineHeight = 5;
    const burgerTop = 5;
    const lineSpacing = 3;
    this.state = {
      toggled: false,
      lineHeight: 'normal',
      barStyles: {
        position: 'fixed',
        backgroundColor: 'rgb(0,0,0,1)',
        width: '100vw',
        height: '35px',
        top: 0,
        zIndex: '2'
      },
      hamburgerStyles:{
        position: 'fixed',
        width: '135px',
        height: '35px',
        backgroundColor: 'rgba(0,0,0,1)',
        zIndex: '3'
      },
      topStyles: {
        position: 'fixed',
        width: '30px',
        backgroundColor: 'white',
        height: lineHeight,
        top: burgerTop,
        left: '2vw',
        borderRadius: '.25vh'
      },
      paddyStyles: {
        position: 'fixed',
        width: '30px',
        backgroundColor: 'white',
        height: lineHeight,
        top: burgerTop+lineHeight+lineSpacing,
        left: '2vw',
        borderRadius: '.25vh'
      },
      bottomStyles: {
        position: 'fixed',
        width: '30px',
        backgroundColor: 'white',
        height: lineHeight,
        top: burgerTop+(lineHeight*2)+(lineSpacing*2),
        left: '2vw',
        borderRadius: '.25vh'
      },
      dropdownStyles: {
        toggled: 'false',
        position: 'fixed',
        width: '120px',
        left: '10px',
        top: '35px',
        backgroundColor: 'rgba(141,141,141,1)',
        height: '0vh',
        transition: '1.5s',
        zIndex: '0'
      }
    }

    this.toggleDropdown = this.toggleDropdown.bind(this);
  }

  toggleDropdown(e) {
    e.preventDefault();
    if (this.state.toggled) {
      this.setState((prevState) => ({
        toggled: false,
        dropdownStyles: {
          ...prevState.dropdownStyles,
          height: '0vh'
        }
      }));
    } else {
      this.setState((prevState) => ({
        toggled: true,
        dropdownStyles: {
          ...prevState.dropdownStyles,
          height: '150px'
        }
      }));
    }
  }

  render(){
    return <div id={'Bar'} style={this.state.barStyles}>
      <div id={'Hamburger'} style={this.state.hamburgerStyles} onClick={this.toggleDropdown}>
        <div id={'TopBun'} style={this.state.topStyles}/>
        <div id={'Paddy'} style={this.state.paddyStyles}/>
        <div id={'BottomBun'} style={this.state.bottomStyles}/>
      </div>

      <div id={'Dropdown'} style={this.state.dropdownStyles} onClick={this.toggleDropdown}>
        {this.props.menuItems.map((item, index) => <MenuBarItem menuText={item} key={index} menuID={index} format={this.state.width}/>)}
      </div>
      </div>
  }
}

export default MenuBar