import React, {Component} from 'react';


export default class Directions extends Component {
    render(){
        // Sections Title / type (i.e. List, table, pdf, map, or persons)
        return <div id='Directions' className={'section'}>
            <h2>Directions</h2>
            <p>Manufacturing Technology, Inc.
                <br/> 7709 5th Ave South
                <br/> Seattle Washington, 98108 </p>
            <iframe title={'Directions to MTI.'}
             src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2693.7457148132657!2d-122.33015437198135!3d47.53381342549202!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54904185c5eb031f%3A0xf4eff6b2dffa461e!2sManufacturing+Technology%2C+Inc+(MTI)!5e0!3m2!1sen!2sus!4v1556750295070!5m2!1sen!2sus"
    frameBorder="0" style={{border:0}} allowFullScreen/>
        </div>
    }
}