import React, {Component} from 'react';
import Q1_Image from '../Assets/Certificate_Q1-2805.png'
import Q1_PDF from '../Assets/Certificate_Q1-2805.pdf'
import ISO_Image from '../Assets/Certificate_ISO-2883.png'
import ISO_PDF from '../Assets/Certificate_ISO-2883.pdf'

export default class Certifications extends Component {
  render(){
    // Sections Title / type (i.e. List, table, pdf, map, or persons)
    return <div id='Certifications' className={'section'}>
      <h2>Certifications</h2>
        <p>At MTI, we continuously strive to make the highest-quality products
           possible for our customers. We maintain quality
           management systems that comply with the requirements of various
           international, federal, and industry quality standards</p>

        <div id="Documents">
          <a href={Q1_PDF}>
            <img src={Q1_Image} alt="API Q1"/>
          </a>
          <a href={ISO_PDF}>
            <img src={ISO_Image} alt="ISO"/>
          </a>
        </div>
    </div>
  }
}