import React, {Component} from 'react';

export default class Services extends Component {
  render(){
    return <div id='Services' className='section'>
      <h2>Services Offered</h2>
      <div className="serviceBackground">High Precision Prototyping</div>
      <div className="serviceBackground">Rotary Shoulder Connectors</div>
      <div className="serviceBackground">CNC Milling</div>
      <div className="serviceBackground">CNC Turning</div>
      <div className="serviceBackground">3D Laser Part-Marking</div>
      <div className="serviceBackground">Serial Traceability</div>
      <div className="serviceBackground">Project Management</div>
      <div className="serviceBackground">Special Requests</div>
    </div>
  }
}