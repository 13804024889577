import React, {Component} from 'react';
// import {Lathes} from "./Equipment/equipment-data"
// import EquipmentType from "./Equipment/EquipmentType";

export default class Equipment extends Component {
  render(){
    // Sections Title / type (i.e. List, table, pdf, map, or persons)
    return <div id='Equipment' className={'section'}>
      <h2>Equipment</h2>
      {/*{Lathes.map((equipmentClass,index) => {*/}
      {/*    return <EquipmentType equipmentClass={equipmentClass} key={index}/>*/}
      {/*  })}*/}
      <div id="Turning">
        <h3>Turning Capabilities</h3>
        <div className="equipmentBackground">Hyundai L400LD (2015)
          <br/> Swing: 28"
          <br/> Cut dia: 24.8"
          <br/> Cut Length: 83.5"
          <br/> Through Bore: 6.5"
          <br/> HP: 50
        </div>
        <div className="equipmentBackground">Hyundai L400C (2012)
          <br/> Swing: 30.7"
          <br/> Cut dia: 24.8"
          <br/> Cut Length: 46.1"
          <br/> Through Bore: 4.61"
          <br/> HP: 35
        </div>
        <div className="equipmentBackground">Hyundai L300C (2012)
          <br/> Swing: 29.5"
          <br/> Cut dia: 22.0"
          <br/> Cut Length: 28.3"
          <br/> Through Bore: 3.54"
          <br/> HP: 35
        </div>
        <div className="equipmentBackground">Hyundai L210A (2013)
          <br/> Swing: 29.5"
          <br/> Cut dia: 13.8"
          <br/> Cut Length: 16.1"
          <br/> Through Bore: 2.56"
          <br/> HP: 20
        </div>
        <div className="equipmentBackground">Hyundai SKT21L (2012)
          <br/> Swing: 21.7"
          <br/> Cut dia: 13.8"
          <br/> Cut Length: 20.9"
          <br/> Through Bore: 2.56"
          <br/> HP: 20​
        </div>
      </div>
      <div id="Milling">
        <h3>Milling Capabilities</h3>
        <div className="equipmentBackground">Hass VF-5/40 2012
          <br/> 5 axis
          <br/> X 50"/ Y 26"/ Z 25"
          <br/> TR160Y Tilt Trunnion
          <br/> 30 HP
          <br/> 10000 RPM
          <br/> High pressure through tool coolant
        </div>
        <div className="equipmentBackground">Fadal 3016L 1998​
          <br/> Full 4 axis​
          <br/> X 30" / Y 16" / Z 20"​
          <br/> Troyke 4th axis​
          <br/> 10 HP​
          <br/> 7000 RPM
        </div>
        <div className="equipmentBackground">Matsuura MC1000VDC​
          <br/> Twin Spindle, Dual Full 4th axis​
          <br/> X 60" / Y 20" / Z 20"
          <br/> Pallet Shuttle
          <br/> 30 HP geared head
          <br/> 4000 RPM
        </div>
        <div className="equipmentBackground">NSK HES 510
          <br/> 50000 RPM
          <br/> 3/4 HP
          <br/> Engraving
        </div>
      </div>
      <div id="Honing">
        <h3>Honing</h3>
        <div className="equipmentBackground">Sunnen SV-2410 (2019)
          <br/> {'\u2205'} Range: 0.75" - 8"
          <br/> Stroke Travel: 30"
          <br/> Spindle Speed: 10 - 600 RPM
        </div>
      </div>
      <div id="Grinding">
        <h3>Grinding</h3>
        <div className="equipmentBackground">STUDER S33-1000 ID/OD (2019)
          <br/> Travel: X 11.2"/ Z 45.3"
          <br/> Max. Workpiece Weight: 330 lbs.
          <br/> Spindle Power: 10hp, 3,200 RPM
        </div>
      </div>
      <div id="Inspection">
        <h3>Inspection Capabilities</h3>
        <div/>
        <p>Over 700 calibrated and cataloged measuring devices</p>
        <div className="equipmentBackground">
          Trimos TU500 24" high precision height gauge
        </div>
        <div className="equipmentBackground">
          Gage Master Optical comparator
        </div>
        <div className="equipmentBackground">
          ​Jones & Lamson Optical comparator
        </div>
        <div className="equipmentBackground">Thermo Scientific Niton XL2 980 GOLDD Alloy analyzer
        </div>
        <div className="equipmentBackground">Leitz RefXI15.9.7 CNC CMM (2015)
          <br/> X 35.5" / Y 59" / Z 27"
          <br/> LSP-5X Scanning Probe
          <br/> Quindos 7 / Thread / API Thread/ Curves/ Blade
          <br/> API certified to inspect:
          <br/> Working API thread Gauges
          <br/> Working Master API thread Gauges
          <br/> Regional Master API thread Gauges
        </div>
      </div>
      <div id="Support">
        <h3>Support Equipment</h3>
        <div className="equipmentBackground">HEM H100A
          <br/> Automatic Horizontal band saw
        </div>
        <div className="equipmentBackground">Keyence (2013)
          <br/> 50W 3D Fiber Laser Marker
        </div>
      </div>
    </div>
  }
}