import React, {Component} from 'react';
import MenuItem from "./MenuItem";
import MenuBar from "./MenuBar";

class Menu extends Component{
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.setState( {
      width: window.innerWidth
    });
  }

  render(){
    if(this.state.width < 750) {
      return <MenuBar menuItems={this.props.menuItems} format={this.state.width}/>
    } else {
      return <div id='Menu' className={'menu'}>
        {this.props.menuItems.map((item, index) => <MenuItem menuText={item} key={index} menuID={index} format={this.state.width}/>)}
      </div>
    }
  }
}

export default Menu