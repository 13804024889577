import React, {Component} from 'react';
// import ReactDOM from 'react-dom'
// import {getScrollPosition, distanceToSection} from "./Helpers/ScrollPosition";

export default class MenuItem extends Component {
  constructor(props) {
    super(props);
    const height =this.setMenuItemHeight();
    const top = this.setTop(height)
    this.state = {
      name: this.props.menuText,
      sectionPosition: null,
      xPosition: 15,
      yPosition:0,
      distance: null,
      styles: {
        width: '10vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: height,
        right: 0,
        top: top
      }
    };

    this.anchorTarget = null;
    this.setMenuItemHeight = this.setMenuItemHeight.bind(this);
    this.setTop = this.setTop.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  handleScroll() {
    // const curPos = window.scrollY;
    // console.log(`${curPos} from ${this.props.menuText}`);
    // const distance =
  }

  setTop(height){
    return (this.props.menuID * (height/1.25)) + (window.innerHeight/2 - (height/1.25)) + ((this.props.menuID-5.5)*(height/2));
  }

  setMenuItemHeight(){
    const windowHeight = window.innerHeight;
    const height = 5*Math.tanh((windowHeight/300)-2);
    return 31 + height;
  }


  handleResize() {
    //console.log(`resized ${window.innerHeight}`);
    const height = this.setMenuItemHeight();
    const top = this.setTop(height);
    this.setState( {
      styles: {
        height: height,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '10vw',
        right: 0,
        top: top
      }
    });
  }

  handleClick(e) {
    e.preventDefault();
    this.anchorTarget.scrollIntoView({behavior: 'smooth', block:"start"});
  }

  componentDidMount() {
    this.anchorTarget = document.getElementById(this.props.menuText);
    window.addEventListener('scroll', this.handleScroll.bind(this));
    window.addEventListener('resize', this.handleResize.bind(this));
  }

  abortController = new AbortController()

componentWillUnmount() {
    this.abortController.abort();
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('scroll', this.handleScroll);
}

  render(){
    // console.log('menu-item rendered.');
    // console.log(`${this.props.menuText} in render`);
    return <div id={`menu${this.props.menuText}`} className='menu-item' style={this.state.styles} onClick={this.handleClick}>
        {this.props.menuText}
    </div>
  }
}