export let data = [
  {
    "title": "Machine Parts Inspector",
    "description": "In support of the manufacturing operations engaged in the production of machined parts, tools, and fixtures, the inspector works under the direction of the Quality Manager and Manufacturing Manager to inspect and ensure product compliance in accordance with the customer specifications and drawings.",
    "job duties": [
      "Perform first part/article, final, in-process and lot validation inspections of manufactured parts per drawing requirements",
      "Verify all prior operations are complete and signed off on the job traveler before acceptance",
      "Verify parts meet all engineering requirements",
      "Assist with the Quality Calibration System",
      "Collect data and gather facts",
      "Prepare inspection records and reports per procedures",
      "Calibration and documentation of metrology equipment",
      "Document non-conformance's and assist with root cause analysis and corrective actions"
    ],
    "requirements": [
      "Extensive knowledge of common inspection measuring equipment including, surface plates, micrometers, height gages, radius gages, bore gauges, and dial indicators. Familiar with quality engineering principles and inspection techniques. Good manual dexterity and adept in the use of precision measuring tools",
      "Ability to read & interpret engineering drawings, standards, and specifications",
      "At least 2 years machined parts/surface plate inspection required",
      "Strong shop related math, interpersonal and problem-solving skills",
      "Ability to effectively communicate complex technical issues",
      "Willingness to provide technical support and training to others",
      "Fundamental understanding of a quality system, documentation, and implementation in a certified environment",
      "Detail oriented, organized and highly motivated",
      "Ability to deal calmly and effectively in stressful situations",
      "Willingness to take on responsibilities"
    ],
    "preferred": [
      "5+ years of experience in quality control and inspection of,high-precision machined parts",
      "Experience in metal manufacturing and machining",
      "CMM and vision/optical system measurement experience"
    ]
  },
  {
    "title": "CNC Lathe Operator / CNC Mill Operator",
    "description": "The machinist operates CNC machines and machining centers to manufacture precision parts per engineering specifications without supervision.",
    "job duties": [
      "Set up manual and CNC equipment without supervision.",
      "Selects, aligns and secures holding fixtures, cutting tools, attachments accessories and materials on machines",
      "Starts and observes machine operation to detect malfunctions or out-of-tolerance machining, and adjusts machine controls or control media as required",
      "Perform first part inspection and in process inspections",
      "Verifies conformance of finished workpiece to specifications, using precision measuring instruments",
      "Verifies dimensions and alignment of assembly, using measuring instruments, such as micrometers, height gages and gage blocks",
      "Change tool setting offsets",
      "Ensure all documentation is complete and correct",
      "Follow work instructions and safety regulations at all times",
      "Detect and report defective equipment, materials and/or faulty operations to supervisor",
      "Identify opportunities for process improvement and increased efficiency",
      "Develop constructive and cooperative working relations with others",
      "Maintain work area in a safe and clean condition",
      "Perform other duties as assigned"
    ],
    "requirements": [
      "2 years of experience in machining or in lieu of experience have a Technical diploma",
      "6 months of experience of reading blueprints, drawings, tools, and gauges",
      "Professionally communicate and work cooperatively with others",
      "Utilize Windows software to manage operational data for future production and company requirements",
      "Ability to lift 50 pounds",
      "Ability to stand for long periods of time",
      "Dependable with a proven attendance record",
      "Ability to follow written and verbal instructions in English",
      "Ability to work in a certified environment"
    ],
    "preferred": [
      "5+ years of experience in a similar position"
    ]
  }
];
