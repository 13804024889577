import React, {Component} from 'react';
import Services from './Sections/Services';
import Equipment from "./Sections/Equipment";
import Certifications from "./Sections/Certifications";
import Contact from "./Sections/Contact";
import Directions from "./Sections/Directions";
import Careers from "./Sections/Careers";

 class Sections extends Component {
    render(){
            // Sections Title / type (i.e. List, table, pdf, map, or persons)
        return (<div id='flex-container'>
                    <Services/>
                    <Equipment/>
                    <Certifications/>
                    <Contact/>
                    <Directions/>
                    <Careers/>
                </div>
        );
    }
}

export default Sections;