import React, {Component} from 'react';
import Position from './Positions/Position';
import {data} from './Positions/position-data'


export default class Careers extends Component {
  render(){
    // Sections Title / type (i.e. List, table, pdf, map, or persons)
    return <div id='Careers' className={'section'}>
      <h2>Careers</h2>
      <p>MTI is always seeking highly motivated professionals in all disciplines to join our team. MTI prides itself on frequent training and assessment of team members. This continual improvement allows motivated individuals to succeed regardless of tenure. We are looking for the best and the brightest and are willing to compensate individuals for their contribution.If you are looking for a career in a fast-paced, professional environment, then we are looking for you.
        <br/> Below are current available positions. Please submit your resume by e-mailing careers@mfgtec.com​ and include the job post title in the subject line. MTI also accepts resumes when no current openings exist for various machine shop positions (operator, inspector, deburr, etc...)
      </p>
      <h2>Available Positions:</h2>
      {data.map((data, index) => {
        return <Position jobData={data} key={index}/>;
      })}
    </div>
  }
}