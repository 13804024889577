import React, {Component} from 'react';

export default class Position extends Component {
  render(){
    return <div>
      <h3>{this.props.jobData['title']}</h3>
      <p className={'label'}>Description: </p>
      <p className={'description'}>{this.props.jobData['description']}</p>
      <p className={'label'}>Job Duties: </p>
      <ul className={'position-list'}>{this.props.jobData['job duties'].map((listItem,index) => <li key={index}>{listItem}</li>)}</ul>
      <p className={'label'}>Requirements: </p>
      <ul className={'position-list'}>{this.props.jobData['requirements'].map((listItem,index) => <li key={index}>{listItem}</li>)}</ul>
      <p className={'label'}>Preferred: </p>
      <ul className={'position-list'}>{this.props.jobData['preferred'].map((listItem,index) => <li key={index}>{listItem}</li>)}</ul>
    </div>
  }
}