import React, {Component} from 'react'
import Title from './Title'
import Menu from './Menu'

const sections =['Services', 'Equipment', 'Certifications', 'Contact', 'Directions', 'Careers'];

class Header extends Component {
    render() {
        return(<div id={'Header'}>
                <Title title={'Manufacturing Technology, Inc.'}/>
                <p id={'introBlurb'}>For over 30 years, MTI has provided unrivaled manufacturing solutions to its customers. Through well-defined process control techniques, and a state-of-the-art facility, MTI is capable of offering complete solutions for the most complex manufacturing challenges.</p>
                <Menu menuItems={sections}/>
            </div>
        )
    }
}

export default Header